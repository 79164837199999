import './wave.css' 
const Curly = () => {
    return (
        <>
            <div className="wave"></div>
        </>
    )
}


export default Curly;